import axios from "axios";

export default {
  data: () => ({
    withdraw: {},
    venue: {},
    customer: {},
    transactionNumber: "",
    explain: "",
  }),

  mounted() {
    this.get_withdraw();
  },

  methods: {
    async get_withdraw() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/withdraws/" + this.$route.query.id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.withdraw = res.data.data.withdraw;
          this.venue = this.withdraw.venue;
          this.customer = res.data.data.withdraw.customer;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    approve_validation(type) {
      if (type === true) {
        if (!this.transactionNumber || !this.explain) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Please fill out the form correctly.",
          });
        } else {
          this.approve(type);
        }
      }
      if (type === false) {
        if (!this.explain) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Please fill out the form correctly.",
          });
        } else {
          this.approve(type);
        }
      }
    },

    async approve(type) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "PATCH",
        url: "/admin/withdraws/" + this.$route.query.id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          approve: type,
          transactionNumber: this.transactionNumber,
          details: this.explain,
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.withdraw = res.data.data.withdraw;
          this.venue = this.withdraw.venue;
          if (type === true) {
            this.$store.commit("snackbar", {
              color: "green",
              text: "Withdrow approved successfully",
            });
          } else {
            this.$store.commit("snackbar", {
              color: "green",
              text: "Withdrow rejected successfully",
            });
          }
          this.$router.push("/withdraw");
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    open_link(link) {
      window.open(link, "_blank");
    },

    fixed(num) {
      this.transactionNumber = (+num).toFixed(2);
    },
  },
};
